import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import ReadingProgress from "../components/ReadingProgress"

// Import styles
import "../styles/blog-post.scss"
import "../styles/job-post.scss"

import ArrowBlue from "../images/inline-svgs/right-arrow-blue.svg"

interface Props {
  data: any
  location: any
}

const JobPost = ({ data, location }: Props) => {
  const post = data.ghostPost

  useEffect(() => {
    // Load Hubspot script from post content
    let scriptNodes = document.querySelectorAll(".post-full-content script")
    for (var i = 0; i < scriptNodes.length; i += 1) {
      var node = scriptNodes[i]
      if (node.textContent.search("hbspt.forms.create") !== -1) {
        var s = document.createElement("script")
        s.type = "text/javascript"
        s.innerHTML = `
          function loadJobForm() {
            ${node.innerHTML}
          }
        `
        document.querySelector(".post-full-content").appendChild(s)

        // Load Hubspot forms api
        let script = document.createElement("script")
        script.src = "//js.hsforms.net/forms/v2.js"
        script.onload = loadJobForm
        document.querySelector(".post-full-content").appendChild(script)
      }
    }

    return () => {}
  }, [])

  const showJobForm = () => {
    let hubspot_form = document.querySelector(".hbspt-form")
    let button = document.querySelector(".job-apply-button")
    if (hubspot_form && button) {
      hubspot_form.style.display = "block"
      button.style.display = "none"
    }
  }

  const progress_bar_target = React.createRef()

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />

      <ReadingProgress target={progress_bar_target} />

      {/* Add codeinjection scripts */}
      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
      ></div>

      <div className="post-view job-post">
        <article
          className={
            "wrapper-800" +
            (post.feature_image == null ? " no-feature-image" : "")
          }
          ref={progress_bar_target}
        >
          {/* Header */}
          <header className="post__header">
            {/* Back button */}
            <div className="post__primary-tag">
              <Link to={`/${post.primary_tag.slug}/`} className="go-back-link">
                <span className="icon">
                  <ArrowBlue />
                </span>
                <span className="text">Back to Open Positions</span>
              </Link>
            </div>

            {/* Title */}
            <h1 className="post__title">{post.title}</h1>
          </header>

          {/* Post Content */}
          <div className="post__body">
            <div
              className="post-full-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
            <div className="job-apply-button">
              <button onClick={() => showJobForm()} className="button--primary">
                Apply Now
              </button>
            </div>
          </div>
        </article>
      </div>

      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_foot }}
      ></div>
    </Layout>
  )
}

export default JobPost

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
